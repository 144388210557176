
export default function HomeTabBlank({setAddBlockModalVisible}) {
    return (
        <div draggable onClick={()=>{setAddBlockModalVisible(true)}} className={
            "flex md:h-48 justify-center items-center box-border cursor-pointer z-20 w-832 flex-col rounded-lg overflow-hidden bg-battBlue mt-11 p-4"}>
            <div className={"text-white text-7xl"}>
                +
            </div>
        </div>
    )
}
