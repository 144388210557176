"use client"
import Navbar from "../components/navbar";
import BattSaasIcon from "../assets/BattSaasIcon.png";
import HomeTab from "../components/HomeTab";
import {useEffect, useState} from "react";
import AddNewBlock from "../components/AddNewBlock";
import DropArea from "../components/DropArea";
import React from "react";
import HomeTabBlank from "../components/HomeTabBlank";


export default function HomePage({keycloak}) {

    const [userName, setUserName] = useState("User");
    const [activeCard, setActiveCard] = useState(null);
    const [addBlockModalVisible, setAddBlockModalVisible] = useState(false);

    const getHomePageData = async () => {
        try {
            const data = localStorage.getItem("homePageTiles");
            const latestId = localStorage.getItem("homePageTileId");
            if (data){
                setHomeTabData(JSON.parse(data))
            }
            if(!latestId){
                localStorage.setItem("homePageTileId", "3")
            }
        }catch (e){
            console.log(e)
        }
    };

    const [homeTabData, setHomeTabData] = useState([
        {
            id: 1,
            type: "",
            logo: BattSaasIcon,
            title: "-Mon",
            linkUrlSim: "",
            text: "Monitors BattDB, alerts on anomalies, and offers visualizations for real-time insights.",
            linkUrl: process.env.REACT_APP_BATT_MON_URL
        },
        {
            id: 2,
            type: "",
            logo: BattSaasIcon,
            title: "-ETL",
            linkUrlSim: "",
            text: "Batch Data Ingestion - Seamlessly import batch data for in-depth analysis.",
            linkUrl: process.env.REACT_APP_BATT_ETL_URL
        },
        {
            id: 3,
            type: "",
            logo: BattSaasIcon,
            title: "ETL-RT",
            linkUrlSim: "",
            text: "Real-Time Data Ingestion - Experience real-time insights through instant data ingestion.",
            linkUrl: process.env.REACT_APP_BATT_RT_URL
        }
    ])


    const removeCard = (id: number) => {
        let data = [...homeTabData];
        data = data.filter(item => {
            return item.id !== id;
        });
        setHomeTabData(data);
        localStorage.setItem("homePageTiles" , JSON.stringify(data))
    }

    useEffect(()=>{
        getHomePageData();
    },[])

    useEffect(() => {
        if (keycloak && keycloak.authenticated) {
            setUserName(keycloak.tokenParsed.preferred_username)
            localStorage.setItem("username", keycloak.tokenParsed.preferred_username)
        } else {
            setUserName(localStorage.getItem("username"))
        }
    }, [keycloak]);


    const onDrop = (index) => {
        let active = null;
        homeTabData.forEach((item)=>{
            if(item.id === activeCard){
                active = item;
            }
        })
        let data = [...homeTabData];
        data = data.filter((item)=>{
            return item.id !== activeCard
        })
        data.splice(index, 0, active);
        setHomeTabData(data);
    }

    const saveNewBlockData = (title: string, description: string, link: string) => {
        const id = JSON.parse(localStorage.getItem("homePageTileId"));
        const data = {
            id: id+1,
            type: "",
            logo: BattSaasIcon,
            title: title,
            linkUrlSim: "",
            text: description,
            linkUrl: link
        }
        const newData = [data].concat(homeTabData);
        setHomeTabData(newData);
        setAddBlockModalVisible(false);
        localStorage.setItem("homePageTileId", id+1);
        localStorage.setItem("homePageTiles" , JSON.stringify(newData));
    }

    const addNewLinkToDB = () => {

    }


    return (
        <main className={"overflow-x-hidden"}>
            {addBlockModalVisible ? <AddNewBlock saveNewBlockData={saveNewBlockData}
                                                 setAddBlockModalVisible={setAddBlockModalVisible}/> : null}
            <div className={"fixed z-0 h-screen w-screen"}>
                <img className={"h-full w-full bg-lightblue object-cover"} src={require("../assets/BattGenie City.png")}
                     alt={""}/>
            </div>
            <div className={"z-20 mb-10 min-h-[80vh] w-screen"}>
                <div className={"z-40"}>
                    <Navbar setAddBlockModalVisible={setAddBlockModalVisible} userName={userName}/>
                </div>
                <div className={"flex justify-center items-center w-full min-h-[80vh] h-full"}>
                    <div className={homeTabData.length>6?"grid md:grid-cols-4 md:gap-x-8":"md:grid md:grid-cols-3 md:gap-x-8"}>
                        {homeTabData.map((item, index) => {
                            return (
                                <div className={"flex flex-col justify-center items-center"} key={item.id}>
                                    <DropArea
                                        index={index}
                                        onDrop={onDrop}/>
                                    <HomeTab
                                        removeCard={removeCard}
                                        id={item.id}
                                        setActiveCard={setActiveCard}
                                        type={item.type}
                                        logo={item.logo}
                                        title={item.title}
                                        linkUrlSim={item.linkUrlSim}
                                        text={item.text}
                                        linkUrl={item.linkUrl}/>
                                </div>
                            )
                        })}
                        {/*<div className={""}>*/}
                            <HomeTabBlank setAddBlockModalVisible={setAddBlockModalVisible}/>
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </main>
    )
}
